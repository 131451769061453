.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #181b2d;
  padding: 5px 10px;
  color: white;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

.left-section, .center-section, .right-section {
  display: flex;
  align-items: center;
}

.left-section .logo-container {
  display: flex;
  align-items: center;
}

.menu-button .icon {
  font-size: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.logo-container .logo {
  width: 140px;
}

.menu {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 12px;
}

.menu-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 10px;
}

.menu-icon {
  margin-right: 5px;
}

.center-section {
  flex-grow: 1;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.separator-menu {
  height: 24px;
  width: 1px;
  background-color: #585858;
  margin: 0 10px; 
}

.separator {
  border-top: 1px solid #585858;
  margin: 15px 0;
}


.header-icons .icon {
  margin: 0 15px;
  cursor: pointer;
}

.header-icons {
  display: flex;
  align-items: center;
}


.user-info {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-info span {
  margin-left: 10px;
}


.header-container {
  margin-bottom: 50px;
}
.right-section {
  margin-right: 19px;
}

ul.menu-links {
  display: flex;
  align-content: center;
  gap: 20px;
}
@media only screen and (max-width: 760px) { 

ul.menu-links {
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0;
}
}

